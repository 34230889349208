import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import FormPage from '../../templates/FormPage';

const host = process.env.GATSBY_HOST;

const webinar = {
  title: 'Bot Platform 2.0: Начало',
  subTitle: 'Дата: 18.02.2019, 13:00 (GMT+2)[br]Длительность: 2 часа[br][br]' +
    'Изучите основы работы с Bot Platform 2.0 и начните строить своих ботов еще быстрее!',
  form: {
    title: 'Contact us',
    type: 'webinar',
    fields: [
      {
        type: 'firstName',
        label: 'Имя',
        value: ''
      },
      {
        type: 'lastName',
        label: 'Фамилия',
        value: ''
      },
      {
        type: 'email',
        label: 'Email',
        value: ''
      },
      {
        type: 'company',
        label: 'Компания',
        value: ''
      },
      {
        type: 'message',
        label: 'Ваше сообщение',
        value: ''
      },
    ],
    button: {
      text: 'Записаться на вебинар',
      link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    }
  }
};

class FormWebinar extends React.PureComponent {
  render() {
    return (
      <Layout>
        <FormPage data={webinar} />
      </Layout>
    );
  }
}

export default withIntl(FormWebinar);

