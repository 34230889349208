import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Clients.scss';
import Image from '../Image/Image';


const clients = [
  { name: 'westernUnion', image: 'clients/western-union.png', width: '203px'},
  { name: 'visa', image: 'clients/visa.png', width: '129px'},
  { name: 'mastercard', image: 'clients/mastercard.png', width: '78px'},
  { name: 'viber', image: 'clients/viber-logo.png', width: '250px'},
  { name: 'mambu', image: 'clients/mambu.png', width: '201px'},
  // { name: 'lanit', image: 'clients/lanit-logo.png', width: '180px'},
  { name: 'pmi', image: 'clients/philip-morris.png', width: '72px'},
  { name: 'pepsico', image: 'clients/pepsico.png', width: '246px'},
  // { name: 'ernst-young', image: 'clients/ernst-young.png', width: '60px'},
  { name: 'petronas', image: 'clients/petronas_logo.png', width: '68px'},
  { name: 'SigmaSoftware-logo', image: 'clients/SigmaSoftware.png', width: '200px'},
  { name: 'piraeus', image: 'clients/piraeus-bank.png', width: '144px'},
  { name: 'metro', image: 'clients/metro.png', width: '165px'},
  { name: 'transferTo', image: 'clients/transferto.png', width: '200px'},
  { name: 'xsolla', image: 'clients/xsolla.png', width: '169px'},
  { name: 'unilever', image: 'clients/unilever.png', width: '58px'},
  { name: 'dtek', image: 'clients/dtek.png', width: '90px'},
  // { name: 'sberbank-ukraine', image: 'clients/sberbank-ukraine.png', width: '190px'},
  { name: 'tascombank-logo', image: 'clients/tascombank-logo.png', width: '100px'},
  { name: 'tsesna', image: 'clients/tbcbank.png', width: '152px'},
  { name: 'ukrnafta', image: 'clients/ukrnafta.png', width: '138px'},
  { name: 'pumb', image: 'clients/pumb.png', width: '60px'},
  { name: 'aBank', image: 'clients/a-bank.png', width: '138px'},
  { name: 'privat', image: 'clients/privatbank.png', width: '273px'},
  { name: 'ideabank-logo', image: 'clients/ideabank-logo.png', width: '200px'},
  { name: 'vostok-logo', image: 'clients/vostok-logo.png', width: '180px'},
  { name: 'varus', image: 'clients/varus.png', width: '195px'},
  { name: 'novus', image: 'clients/novus-logo.png', width: '145px'},
  { name: 'atb', image: 'clients/atb-logo.png', width: '60px'},
  { name: 'glovo-logo', image: 'clients/glovo.png', width: '150px'}
];


class Clients extends PureComponent {
  constructor(props) {
    super(props);
    const listClients = [];
    listClients.push(...clients);

    this.state = {
      left: 0,
      listClients,
    };
  }

  componentDidMount() {
    if (this.interval) return;
    this._handleStartAutoScroll();
    this.container.addEventListener('mouseenter', () => this._handleHover());
    this.container.addEventListener('mouseleave', () => this._handleStartAutoScroll());
    // window.addEventListener('blur', () => this._handleHover());
    // window.addEventListener('focus', () => this._handleStartAutoScroll());
  }

  _handleStartAutoScroll() {
    if (!this.container) return;
    let scrollLeft = this.container.scrollLeft;
    const speedScroll = 0.3;
    this.interval = setInterval(() => {
      const { listClients } = this.state;
      if (this[`ref${listClients[0].name}`]) {
        const firstElement = this[`ref${listClients[0].name}`].getBoundingClientRect();
        const positionFirstElement = firstElement.left;
        const widthFirstElement = firstElement.width;
        if (positionFirstElement < -600) {
          const copyEl = this[`ref${listClients[0].name}`];
          this[`ref${listClients[0].name}`].remove();
          this.container.children[0].appendChild(copyEl);
          listClients.push(...listClients.splice(0, 1));
          const correctScroll = scrollLeft - widthFirstElement + speedScroll;
          this.container.scrollTo(correctScroll, 0);
          scrollLeft = correctScroll;
        } else {
          this.container.scrollTo(scrollLeft + speedScroll, 0);
          scrollLeft += speedScroll;
        }
      }
    }, 10);
  }

  _handleStopAutoScroll() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  _handleHover() {
    this._handleStopAutoScroll();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { listClients } = this.state;

    return (
      <div className="main__clients__wrap">
        <div
          className="main__clients"
          style={{ background: this.props.background }}
          ref={el => (this.container = el)}
        >
          <div
            className="main__clients__container"
          >
            {listClients.map((client, index) => (
              <div
                key={index}
                ref={el => (this[`ref${client.name}`] = el)}
                className={cn('main__clients__item', `${client.name}`)}
                style={{width: client.width}}
              >
                <Image
                  className={cn('main__clients__item__img')}
                  file={client.image}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="main__clients__mask"/>
      </div>
    );
  }
}

Clients.propTypes = {
  background: PropTypes.string
};

export default Clients;

