import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './FormPage.scss';
import Utils from '../../utils/utils';
import Form from '../../components/Form';
import Clients from '../../components/Clients/Clients';

class FormPage extends PureComponent {
  render() {
    const { data, intl } = this.props;

    if (data.form.type === 'autoSend') {
      return (
        <div className="formPage__wrapper">
          <div className="formPage">
            <div className="formPage__image">
              <Form data={data.form} />
            </div>
          </div>
          <Clients background={'#fff'}/>
        </div>
      );
    }

    return (
      <div>
        <div className="formPage__wrapper">
          <div className="grid-wrapper formPage">
            <div className="col-7 formPage__text">
              <div className="formPage__title">
                <h1>{intl.formatMessage({id: data.title})}</h1>
              </div>
              <div className="formPage__subTitle">
                <span
                  dangerouslySetInnerHTML={{
                    __html: Utils.bbCodeToHtml(intl.formatMessage({id: data.subTitle}))
                  }}
                />
              </div>
            </div>
            <div className="col-5 formPage__image">
              <Form data={data.form} />
            </div>
          </div>
        </div>
        <Clients background={'#fff'}/>
      </div>
    );
  }
}

FormPage.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
  type: PropTypes.string,
};

export default injectIntl(FormPage);

