const DateUtils = {

  // Получить unixtime на сейчас
  unixtime() {
    return Math.floor(new Date().getTime() / 1000);
  },

  // Привести дату к началу периода
  startOf(date, condition) {
    const newDate = new Date(date);
    switch (condition) {
      case 'day':
        newDate.setHours(0, 0, 0, 0);
        break;
      case 'week':
        const first = date.getDate() - date.getDay() + 1;
        newDate.setDate(first);
        newDate.setHours(0, 0, 0, 0);
        break;
      default:
        throw new Error(`Unsupported condition "${condition}"`);
    }
    return newDate;
  },

  // Привести дату к концу периода
  endOf(date, condition) {
    const newDate = new Date(date);
    switch (condition) {
      case 'day':
        newDate.setHours(23, 59, 59, 0);
        break;
      case 'week':
        const first = date.getDate() - date.getDay() + 1;
        const last = first + 6;
        newDate.setDate(last);
        newDate.setHours(23, 59, 59, 0);
        break;
      default:
        throw new Error(`Unsupported condition "${condition}"`);
    }
    return newDate;
  },

  // Получить unixtime конкретной даты
  dateToUnixtime(date) {
    return Math.floor(date.getTime() / 1000);
  },

  // Получить список месяцев
  getMonth(month, lang) {
    let monthNames;
    switch (lang) {
      case 'ru':
        monthNames = ['Января', 'Февраля', 'Марта',
          'Апреля', 'Мая', 'Июня',
          'Июля', 'Августа', 'Сентября',
          'Октября', 'Ноября', 'Декабря'];
        break;
      case 'fr':
        monthNames = ['janvier', 'février', 'mars',
          'avril', 'mai', 'juin',
          'juillet', 'août', 'septembre',
          'octobre', 'novembre', 'décembre'];
        break;
      default:
        monthNames = ['January', 'February', 'March',
          'April', 'May', 'June',
          'July', 'August', 'September',
          'October', 'November', 'December'];
    }
    return monthNames[month];
  },

  addZero(num) {
    return (num < 10) ? `0${num}` : num;
  },

  // Полчить объект с временем
  unixtime2Date(unixtime) {
    const mtime = +unixtime * 1000;
    const date = new Date(mtime);
    return {
      month: date.getMonth(),
      year: date.getFullYear(),
      day: date.getDate(),
      h: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds()
    };
  },

  // Форматирование даты
  toDate(unixtime, format, lang = 'en') {
    if (!unixtime) return '-';

    const date = this.unixtime2Date(unixtime);

    switch (format) {
      case 'YYYY.MM.DD':
        return `${date.year}.${this.addZero(date.month + 1)}.${this.addZero(date.day)}`;
      case 'DD M YYYY':
        return `${this.addZero(date.day)} ${this.getMonth(date.month, lang)} ${date.year}`;
      case 'DD m YYYY':
        return `${this.addZero(date.day)} ${this.getMonth(date.month, lang).substr(0, 3)} ${date.year}`;
      case 'DD M YYYY, HH:mm':
        return `${this.addZero(date.day)} ${this.getMonth(date.month, lang)} ${date.year}, ${date.h}:${this.addZero(date.m)}`;
      case 'DD M HH:mm':
        return `${this.addZero(date.day)} ${this.getMonth(date.month, lang)} ${date.h}:${this.addZero(date.m)}`;
      case 'DD m HH:mm':
        return `${this.addZero(date.day)} ${this.getMonth(date.month, lang).substr(0, 3)} ${date.h}:${this.addZero(date.m)}`;
      case 'm DD, YYYY; HH:mm':
        return `${this.getMonth(date.month, lang).substr(0, 3)} ${this.addZero(date.day)}, ${date.year}; ${date.h}:${this.addZero(date.m)}`;
      case 'M DD, YYYY; HH:mm':
        return `${this.getMonth(date.month, lang)} ${this.addZero(date.day)}, ${date.year}; ${date.h}:${this.addZero(date.m)}`;
      case 'DD.MM.YY':
        return `${this.addZero(date.day)}.${this.addZero(date.month + 1)}.${String(date.year).slice(-2)}`;
      case 'HH:mm:ss':
        return `${date.h}:${this.addZero(date.m)}: ${this.addZero(date.s)}`;
      case 'HH:mm':
        return `${date.h}:${this.addZero(date.m)}`;
      default:
        return unixtime;
    }
  },

  nonoSecToUnixtime(nano) {
    return (nano / 1000000000).toFixed(0);
  },

  // Относительная дата
  relativeDate(unixtime) {
    const now = this.unixtime();
    const delta = Math.round(now - unixtime);
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    let fuzzy;

    if (delta < 30) {
      fuzzy = 'just now';
    } else if (delta < minute) {
      fuzzy = `${delta} seconds ago`;
    } else if (delta < 2 * minute) {
      fuzzy = 'a minute ago';
    } else if (delta < hour) {
      fuzzy = `${Math.floor(delta / minute)} minutes ago`;
    } else if (Math.floor(delta / hour) === 1) {
      fuzzy = '1 hour ago';
    } else if (delta < day) {
      fuzzy = `${Math.floor(delta / hour)} hours ago`;
    } else if (delta < day * 2) {
      fuzzy = 'yesterday';
    } else if (delta < month) {
      fuzzy = `${Math.floor(delta / day)} days ago`;
    } else if (Math.floor(delta / month) === 1) {
      fuzzy = 'month ago';
    } else {
      fuzzy = `${Math.floor(delta / month)} months ago`;
    }
    return fuzzy;
  },

  todayStart() {
    return this.startOf(this.unixtime() * 1000, 'day').getTime() / 1000;
  },

  todayEnd() {
    return this.endOf(this.unixtime() * 1000, 'day').getTime() / 1000;
  },

  tomorrowStart() {
    return this.startOf((this.unixtime() + 24 * 60 * 60) * 1000, 'day').getTime() / 1000;
  },

  tomorrowEnd() {
    return this.endOf((this.unixtime() + 24 * 60 * 60) * 1000, 'day').getTime() / 1000;
  },

  yesterdayStart() {
    return this.startOf((this.unixtime() - 24 * 60 * 60) * 1000, 'day').getTime() / 1000;
  },

  yesterdayEnd() {
    return this.endOf((this.unixtime() - 24 * 60 * 60) * 1000, 'day').getTime() / 1000;
  },

  isToday(unixtime) {
    return unixtime >= this.todayStart() && unixtime < this.todayEnd();
  },

  isTomorrow(unixtime) {
    return unixtime >= this.tomorrowStart() && unixtime < this.tomorrowEnd();
  },

  isYesterday(unixtime) {
    return unixtime >= this.yesterdayStart() && unixtime < this.yesterdayEnd();
  },

  correctTime(h, m) {
    let hours = +h;
    let minutes = +m;
    if (isNaN(hours)) {
      hours = 0;
    } else if (h > 23) {
      hours = 23;
    }
    if (isNaN(minutes)) {
      minutes = 0;
    } else if (m > 59) {
      minutes = 59;
    }
    return {
      hours: DateUtils.addZero(hours),
      minutes: DateUtils.addZero(minutes),
    };
  }
};

export default DateUtils;
